window.menuData = [
  {
    "title": "components.navigation.shared.explorer_links.wines",
    "icon": "wine",
    "queryParams": [{ "name": "wine_type_ids", "value": [1, 2, 3, 4] }],
    "itemShowAll": {
      "title": "components.navigation.shared.explorer_links.browse_all_wines",
      "queryParams": []
    },
    "ads": [
      {
        "title": "components.navigation.shared.explorer_links.browse_toprated_sauvignon_blanc",
        "thumbnailType": 2,
        "queryParams": [
          { "name": "grape_ids", "value": [17] },
          { "name": "order_by", "value": "" },
          { "name": "order", "value": "desc" }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.extraordinary_wines_for_someone_quite_special",
        "thumbnailType": 10,
        "queryParams": [
          { "name": "price_range_min", "value": 50 },
          { "name": "price_range_max", "value": 100 }
        ]
      }
    ],
    "items": [
      {
        "title": "components.navigation.shared.explorer_links.red",
        "queryParams": [{ "name": "wine_type_ids", "value": [1] }],
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.napa_valley_cabernet_sauvignon",
            "queryParams": [{ "name": "wine_style_ids", "value": [289] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.californian_pinot_noir",
            "queryParams": [{ "name": "wine_style_ids", "value": [157] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.tuscan_red",
            "queryParams": [{ "name": "wine_style_ids", "value": [292] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.italian_amarone",
            "queryParams": [{ "name": "wine_style_ids", "value": [3] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.argentinian_malbec",
            "queryParams": [{ "name": "wine_style_ids", "value": [98] }]
          }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.white",
        "queryParams": [{ "name": "wine_type_ids", "value": [2] }],
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.napa_valley_chardonnay",
            "queryParams": [{ "name": "wine_style_ids", "value": [291] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.californian_sauvignon_blanc",
            "queryParams": [{ "name": "wine_style_ids", "value": [199] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.northern_italy_white",
            "queryParams": [{ "name": "wine_style_ids", "value": [131] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.german_riesling",
            "queryParams": [{ "name": "wine_style_ids", "value": [186] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.upper_loire_white",
            "queryParams": [{ "name": "wine_style_ids", "value": [235] }]
          }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.sparkling",
        "queryParams": [{ "name": "wine_type_ids", "value": [3] }]
      },
      {
        "title": "components.navigation.shared.explorer_links.rose",
        "queryParams": [{ "name": "wine_type_ids", "value": [4] }]
      },
      {
        "title": "components.navigation.shared.explorer_links.fortified",
        "queryParams": [{ "name": "wine_type_ids", "value": [24] }]
      },
      {
        "title": "components.navigation.shared.explorer_links.dessert",
        "queryParams": [{ "name": "wine_type_ids", "value": [7] }]
      }
    ]
  },
  {
    "title": "components.navigation.shared.explorer_links.offers",
    "icon": "offers",
    "queryParams": [
      { "name": "order_by", "value": "best_picks" },
      { "name": "order", "value": "desc" },
      { "name": "price_types", "value": ["xdo"] },
      { "name": "hide_show_only_offers", "value": true }
    ],
    "itemShowAll": {
      "title": "components.navigation.shared.explorer_links.browse_all_offers",
      "queryParams": [
        { "name": "order_by", "value": "discount_percent" },
        { "name": "order", "value": "desc" },
        { "name": "price_types", "value": ["xdo"] }
      ]
    },
    "ads": [
      {
        "title": "components.navigation.shared.explorer_links.the_best_wine_you_can_get_below",
        "titleParams": { "amount": "$20" },
        "thumbnailType": 7,
        "queryParams": [
          { "name": "price_range_min", "value": 0 },
          { "name": "price_range_max", "value": 20 },
          { "name": "order_by", "value": "" },
          { "name": "order", "value": "desc" },
          { "name": "discount_prices", "value": "true" }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.wines_with_awesome_savings_right_now",
        "thumbnailType": 8,
        "queryParams": [
          { "name": "order_by", "value": "discount_percent" },
          { "name": "order", "value": "desc" },
          { "name": "discount_prices", "value": "true" }
        ]
      }
    ],
    "items": [
      {
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.less_than",
            "titleParams": { "amount": "$20", "currency_code": "USD" },
            "queryParams": [
              { "name": "order_by", "value": "discount_percent" },
              { "name": "order", "value": "desc" },
              { "name": "price_range_min", "value": 0 },
              { "name": "price_range_max", "value": 20 },
              { "name": "discount_prices", "value": "true" }
            ]
          },
          {
            "title": "components.navigation.shared.explorer_links.from_to",
            "titleParams": { "from": "$20", "to": "$30", "currency_code": "USD" },
            "queryParams": [
              { "name": "order_by", "value": "discount_percent" },
              { "name": "order", "value": "desc" },
              { "name": "price_range_min", "value": 20 },
              { "name": "price_range_max", "value": 30 },
              { "name": "discount_prices", "value": "true" }
            ]
          },
          {
            "title": "components.navigation.shared.explorer_links.from_to",
            "titleParams": { "from": "$30", "to": "$40", "currency_code": "USD" },
            "queryParams": [
              { "name": "order_by", "value": "discount_percent" },
              { "name": "order", "value": "desc" },
              { "name": "price_range_min", "value": 30 },
              { "name": "price_range_max", "value": 40 },
              { "name": "discount_prices", "value": "true" }
            ]
          },
          {
            "title": "components.navigation.shared.explorer_links.over",
            "titleParams": { "amount": "$40", "currency_code": "USD" },
            "queryParams": [
              { "name": "order_by", "value": "discount_percent" },
              { "name": "order", "value": "desc" },
              { "name": "price_range_min", "value": 40 },
              { "name": "price_range_max", "value": 500 },
              { "name": "discount_prices", "value": "true" }
            ]
          }
        ]
      },
      {
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.reds_on_offer",
            "queryParams": [
              { "name": "wine_type_ids", "value": [1] },
              { "name": "order_by", "value": "discount_percent" },
              { "name": "order", "value": "desc" },
              { "name": "discount_prices", "value": "true" }
            ]
          },
          {
            "title": "components.navigation.shared.explorer_links.whites_on_offer",
            "queryParams": [
              { "name": "wine_type_ids", "value": [2] },
              { "name": "order_by", "value": "discount_percent" },
              { "name": "order", "value": "desc" },
              { "name": "discount_prices", "value": "true" }
            ]
          }
        ]
      }
    ]
  },
  {
    "title": "components.navigation.shared.explorer_links.pairings",
    "icon": "cheese",
    "queryParams": [],
    "ads": [
      {
        "title": "components.navigation.shared.explorer_links.goes_incredibly_well_with_steak",
        "thumbnailType": 9,
        "queryParams": [
          { "name": "food_ids", "value": [4] },
          { "name": "price_range_min", "value": 15 },
          { "name": "price_range_max", "value": 30 },
          { "name": "order_by", "value": "ratings_count" },
          { "name": "order", "value": "desc" }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.choose_the_perfect_wine_for_soft_cheese",
        "thumbnailType": 3,
        "queryParams": [
          { "name": "food_ids", "value": [35] },
          { "name": "price_range_max", "value": 28 }
        ]
      }
    ],
    "items": [
      {
        "items": [
          {
            "title": "food_pairings.beef",
            "icon": "icon-foodpairing-4",
            "queryParams": [
              { "name": "food_ids", "value": [4] },
              { "name": "wine_type_ids", "value": [1] }
            ]
          },
          {
            "title": "food_pairings.lamb",
            "icon": "icon-foodpairing-8",
            "queryParams": [
              { "name": "food_ids", "value": [8] },
              { "name": "wine_type_ids", "value": [1] }
            ]
          },
          {
            "title": "food_pairings.veal",
            "icon": "icon-foodpairing-9",
            "queryParams": [
              { "name": "food_ids", "value": [9] },
              { "name": "wine_type_ids", "value": [1] }
            ]
          },
          {
            "title": "food_pairings.pork",
            "icon": "icon-foodpairing-10",
            "queryParams": [
              { "name": "food_ids", "value": [10] },
              { "name": "wine_type_ids", "value": [1] }
            ]
          },
          {
            "title": "food_pairings.game",
            "icon": "icon-foodpairing-11",
            "queryParams": [
              { "name": "food_ids", "value": [11] },
              { "name": "wine_type_ids", "value": [1] }
            ]
          },
          {
            "title": "food_pairings.poultry",
            "icon": "icon-foodpairing-20",
            "queryParams": [
              { "name": "food_ids", "value": [20] },
              { "name": "wine_type_ids", "value": [1] }
            ]
          },
          {
            "title": "food_pairings.mushrooms",
            "icon": "icon-foodpairing-34",
            "queryParams": [
              { "name": "food_ids", "value": [34] },
              { "name": "wine_type_ids", "value": [1] }
            ]
          }
        ]
      },
      {
        "items": [
          {
            "title": "food_pairings.cured_meat",
            "icon": "icon-foodpairing-41",
            "queryParams": [
              { "name": "food_ids", "value": [41] },
              { "name": "wine_type_ids", "value": [1] }
            ]
          },
          {
            "title": "food_pairings.goat_cheese",
            "icon": "icon-foodpairing-39",
            "queryParams": [
              { "name": "food_ids", "value": [39] },
              { "name": "wine_type_ids", "value": [2] }
            ]
          },
          {
            "title": "food_pairings.mature_and_hard_cheese",
            "icon": "icon-foodpairing-17",
            "queryParams": [
              { "name": "food_ids", "value": [17] },
              { "name": "wine_type_ids", "value": [2] }
            ]
          },
          {
            "title": "food_pairings.mild_and_soft_cheese",
            "icon": "icon-foodpairing-35",
            "queryParams": [
              { "name": "food_ids", "value": [35] },
              { "name": "wine_type_ids", "value": [2] }
            ]
          },
          {
            "title": "food_pairings.pasta",
            "icon": "icon-foodpairing-5",
            "queryParams": [
              { "name": "food_ids", "value": [5] },
              { "name": "wine_type_ids", "value": [2] }
            ]
          },
          {
            "title": "food_pairings.spicy_food",
            "icon": "icon-foodpairing-15",
            "queryParams": [
              { "name": "food_ids", "value": [15] },
              { "name": "wine_type_ids", "value": [2] }
            ]
          }
        ]
      },
      {
        "items": [
          {
            "title": "food_pairings.aperitif",
            "icon": "icon-foodpairing-40",
            "queryParams": [{ "name": "food_ids", "value": [40] }]
          },
          {
            "title": "food_pairings.appetizers_and_snacks",
            "icon": "icon-foodpairing-27",
            "queryParams": [{ "name": "food_ids", "value": [27] }]
          },
          {
            "title": "food_pairings.lean_fish",
            "icon": "icon-foodpairing-28",
            "queryParams": [{ "name": "food_ids", "value": [28] }]
          },
          {
            "title": "food_pairings.rich_fish",
            "icon": "icon-foodpairing-12",
            "queryParams": [
              { "name": "food_ids", "value": [12] },
              { "name": "wine_type_ids", "value": [2] }
            ]
          },
          {
            "title": "food_pairings.shellfish",
            "icon": "icon-foodpairing-13",
            "queryParams": [{ "name": "food_ids", "value": [13] }]
          },
          {
            "title": "food_pairings.vegetarian",
            "icon": "icon-foodpairing-19",
            "queryParams": [{ "name": "food_ids", "value": [19] }]
          }
        ]
      }
    ]
  },
  {
    "title": "components.navigation.shared.explorer_links.grapes",
    "icon": "grapes",
    "queryParams": [],
    "ads": [
      {
        "title": "components.navigation.shared.explorer_links.great_pinot_noir_offers",
        "thumbnailType": 1,
        "queryParams": [
          { "name": "grape_ids", "value": [14] },
          { "name": "price_range_min", "value": 0 },
          { "name": "price_range_max", "value": 500 },
          { "name": "order_by", "value": "discount_percent" },
          { "name": "order", "value": "desc" }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.wine_that_will_start_any_party",
        "thumbnailType": 4,
        "queryParams": [
          { "name": "wine_type_ids", "value": [3] },
          { "name": "price_range_min", "value": 23 },
          { "name": "price_range_max", "value": 30 },
          { "name": "order_by", "value": "ratings_count" },
          { "name": "order", "value": "desc" }
        ]
      }
    ],
    "items": [
      {
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.cabernet_sauvignon",
            "queryParams": [{ "name": "grape_ids", "value": [2] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.merlot",
            "queryParams": [{ "name": "grape_ids", "value": [10] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.chardonnay",
            "queryParams": [{ "name": "grape_ids", "value": [5] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.pinot_noir",
            "queryParams": [{ "name": "grape_ids", "value": [14] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.malbec",
            "queryParams": [{ "name": "grape_ids", "value": [9] }]
          }
        ]
      },
      {
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.sauvignon_blanc",
            "queryParams": [{ "name": "grape_ids", "value": [17] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.shiraz_syrah",
            "queryParams": [{ "name": "grape_ids", "value": [1] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.zinfandel",
            "queryParams": [{ "name": "grape_ids", "value": [21] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.nebbiolo",
            "queryParams": [{ "name": "grape_ids", "value": [12] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.sangiovese",
            "queryParams": [{ "name": "grape_ids", "value": [16] }]
          }
        ]
      },
      {
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.pinot_grigio",
            "queryParams": [{ "name": "grape_ids", "value": [34] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.riesling",
            "queryParams": [{ "name": "grape_ids", "value": [15] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.chenin_blanc",
            "queryParams": [{ "name": "grape_ids", "value": [6] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.moscato",
            "queryParams": [{ "name": "grape_ids", "value": [59] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.albarino",
            "queryParams": [{ "name": "grape_ids", "value": [49] }]
          }
        ]
      }
    ]
  },
  {
    "title": "components.navigation.shared.explorer_links.regions_menu",
    "icon": "pin",
    "queryParams": [],
    "itemShowAll": {
      "title": "components.navigation.shared.explorer_links.all_regions",
      "queryParams": []
    },
    "ads": [
      {
        "title": "components.navigation.shared.explorer_links.napa_valleys_most_wanted",
        "thumbnailType": 5,
        "queryParams": [
          { "name": "region_ids", "value": [25] },
          { "name": "price_range_max", "value": 40 },
          { "name": "order_by", "value": "ratings_count" },
          { "name": "order", "value": "desc" }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.topnotch_german_rieslings",
        "thumbnailType": 6,
        "queryParams": [
          { "name": "wine_style_ids", "value": [186] },
          { "name": "min_rating", "value": 4 },
          { "name": "price_range_max", "value": 35 }
        ]
      }
    ],
    "items": [
      {
        "title": "components.navigation.shared.explorer_links.popular_regions",
        "queryParams": [],
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.rioja",
            "queryParams": [{ "name": "region_ids", "value": [492] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.barolo",
            "queryParams": [],
            "link": "/explore/barolo"
          },
          {
            "title": "components.navigation.shared.explorer_links.chianti",
            "queryParams": [],
            "link": "/explore/chianti"
          },
          {
            "title": "components.navigation.shared.explorer_links.bordeaux",
            "queryParams": [],
            "link": "/explore/bordeaux"
          },
          {
            "title": "components.navigation.shared.explorer_links.champagne",
            "queryParams": [],
            "link": "/explore/champagne"
          },
          {
            "title": "components.navigation.shared.explorer_links.marlborough_sauvignon_blanc",
            "queryParams": [],
            "link": "/explore/marlborough"
          }
        ]
      },
      {
        "title": "",
        "noTitle": true,
        "queryParams": [],
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.puglia",
            "queryParams": [],
            "link": "/explore/puglia"
          },
          {
            "title": "components.navigation.shared.explorer_links.prosecco",
            "queryParams": [],
            "link": "/explore/prosecco"
          },
          {
            "title": "components.navigation.shared.explorer_links.cortes_du_rone",
            "queryParams": [],
            "link": "/explore/cotes-du-rhone"
          },
          {
            "title": "components.navigation.shared.explorer_links.willamette_valley",
            "queryParams": [],
            "link": "/explore/willamette-valley"
          },
          {
            "title": "components.navigation.shared.explorer_links.chateauneuf_du_pape",
            "queryParams": [],
            "link": "/explore/chateauneuf-du-pape"
          }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.usa",
        "queryParams": [{ "name": "country_codes", "value": ["us"] }],
        "items": [
          {
            "title": "components.navigation.shared.explorer_links.california",
            "queryParams": [{ "name": "region_ids", "value": [24] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.sonoma_county",
            "queryParams": [{ "name": "region_ids", "value": [26] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.napa_valley",
            "queryParams": [{ "name": "region_ids", "value": [25] }]
          },
          {
            "title": "components.navigation.shared.explorer_links.oregon",
            "queryParams": [{ "name": "region_ids", "value": [45] }]
          }
        ]
      },
      {
        "title": "components.navigation.shared.explorer_links.italy",
        "queryParams": [{ "name": "country_codes", "value": ["it"] }]
      },
      {
        "title": "components.navigation.shared.explorer_links.portugal",
        "queryParams": [{ "name": "country_codes", "value": ["pt"] }]
      },
      {
        "title": "components.navigation.shared.explorer_links.chile",
        "queryParams": [{ "name": "country_codes", "value": ["cl"] }]
      },
      {
        "title": "components.navigation.shared.explorer_links.new_zealand",
        "queryParams": [{ "name": "country_codes", "value": ["nz"] }]
      },
      {
        "title": "components.navigation.shared.explorer_links.australia",
        "queryParams": [{ "name": "country_codes", "value": ["au"] }]
      }
    ]
  },
  {
    "title": "components.navigation.shared.navigation_premium.premium",
    "icon": "premium",
    "queryParams": [],
    "itemShowAll": {},
    "ads": [],
    "items": [],
    "link": "/premium"
  },
  {
    "title": "components.navigation.shared.explorer_links.wineries",
    "icon": "winery",
    "extra": "components.navigation.shared.explorer_links.new",
    "queryParams": [],
    "itemShowAll": {},
    "ads": [],
    "items": [],
    "link": "/wineries"
  }
]
;